/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import clsx from "clsx";
import { register } from "../core/_requests";
import { Link, useNavigate } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useIntl } from "react-intl";
import { useAuth } from "../core/Auth";
import rulesInfoPdf from "../../../../../src/_metronic/assets/files/rulesInfo.pdf";
import confidendalPolicyPdf from "../../../../../src/_metronic/assets/files/confidendal-policy.pdf";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Регулярное выражение
const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+\-={}[\]:;"'<>,.?]+$/;

const initialValues = {
  nickname: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  showPassword: false,
  showConfirmPassword: false,
  promo: "",
};

export function Registration() {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<any>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { saveAuth } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();

  const registrationSchema = Yup.object().shape({
    nickname: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_EMAIL" }))
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(passwordRegex, "Пароль не должен содержать спецсимволы")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    changepassword: Yup.string()
      .matches(passwordRegex, "Пароль не должен содержать спецсимволы")
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" })
      ),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.TERMS_REQUIRED" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await register(
          values.email,
          values.nickname,
          values.password,
          values.promo,
          token
        );
        setRefreshReCaptcha(r => !r);

        // saveAuth(auth);
        // const { data: user } = await getUserByToken();
        // setCurrentUser(user);
        // navigate("/auth/login", {
        //   state: { action: { type: "SUCCESS_REGISTERED" } },
        // });
        navigate("/", {
          state: { action: { type: "SUCCESS_REGISTERED" } },
        });
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        let statusText = intl.formatMessage({
          id: "AUTH.VALIDATION.WRONG_CREDITINALS",
        });

        if (error) {
          statusText =
            error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail;
        }

        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        setRefreshReCaptcha(r => !r);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    /* do something like submit a form and then refresh recaptcha */
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        process.env.REACT_APP_GOOGLE_PUBLIC_KEY ??
        "6Lfmh30nAAAAAHZaOqxgTAEYSAPBHVHNYhDC2DBm"
      }
    >
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-9">
          {/* begin::Title */}
          <h1 className="text-dark fw-bolder mb-3">
            {intl.formatMessage({ id: "AUTH.GENERAL.REGISTER_TITLE" })}
          </h1>
          {/* end::Title */}
        </div>

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        />

        {/* begin::Form group nickname */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.USERNAME.TITLE" })}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.USERNAME.TITLE",
            })}
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("nickname")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.nickname && formik.errors.nickname,
              },
              {
                "is-valid": formik.touched.nickname && !formik.errors.nickname,
              }
            )}
          />
          {formik.touched.nickname && formik.errors.nickname && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.nickname}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group Email */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
          </label>
          <input
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
            type="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className="fv-row mb-5" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "AUTH.INPUT.PASSWORD.TITLE" })}
            </label>
            <div className="position-relative mb-3">
              <input
                type={formik.values.showPassword ? "text" : "password"}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                })}
                autoComplete="off"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
                onClick={() =>
                  formik.setFieldValue(
                    "showPassword",
                    !formik.values.showPassword
                  )
                }
              >
                <i
                  className={`bi fs-2 ${
                    formik.values.showPassword
                      ? "bi-eye-slash-fill"
                      : "bi-eye-fill"
                  }`}
                ></i>
              </span>
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className="text-muted">
            {intl.formatMessage(
              { id: "AUTH.VALIDATION.MIN_LENGTH_TITLE" },
              { length: 8 }
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
          </label>
          <div className="position-relative">
            <input
              type={formik.values.showConfirmPassword ? "text" : "password"}
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.CONFIRM_PASSWORD",
              })}
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.changepassword &&
                    formik.errors.changepassword,
                },
                {
                  "is-valid":
                    formik.touched.changepassword &&
                    !formik.errors.changepassword,
                }
              )}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
              onClick={() =>
                formik.setFieldValue(
                  "showConfirmPassword",
                  !formik.values.showConfirmPassword
                )
              }
            >
              <i
                className={`bi fs-2 ${
                  formik.values.showConfirmPassword
                    ? "bi-eye-slash-fill"
                    : "bi-eye-fill"
                }`}
              ></i>
            </span>
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group PROMO */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.PROMO" })}
          </label>
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.PROMO",
            })}
            autoComplete="off"
            {...formik.getFieldProps("promo")}
            className={clsx(
              "form-control bg-transparent bg-light-info text-info"
            )}
          />
          {formik.touched.promo && formik.errors.promo && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.promo}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label
            className="form-check form-check-inline"
            htmlFor="kt_login_toc_agree"
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span>
              Я подтверждаю, что достиг(-ла) возраста 18 лет или минимально
              допустимого возраста для игры в азартные игры, предусмотренного
              законодательством страны моего проживания, являюсь
              дееспособным(-ой) и не имею зависимости от азартных игр. Я
              подтверждаю, что ознакомлен(-на), согласен(-на) и принимаю{" "}
              <a
                href={rulesInfoPdf}
                target="_blank"
                className="fw-bold text-freewin text-hover-white"
              >
                {intl.formatMessage({ id: "FOOTER.TERMS_AND_CONDITIONS" })}
              </a>
              . Я также ознаколен(-на) и согласен(-на) с{" "}
              <a
                href={confidendalPolicyPdf}
                target="_blank"
                className="fw-bold text-freewin text-hover-white"
              >
                Политикой конфиденциальности
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms ||
              !token
            }
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({
                  id: "AUTH.GENERAL.REGISTER_BUTTON",
                })}
              </span>
            )}
            {!token && (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "GLOBAL.WAIT",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link reloadDocument to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              {intl.formatMessage({
                id: "AUTH.GENERAL.BACK_BUTTON",
              })}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </GoogleReCaptchaProvider>
  );
}
