import type { FC } from "react";
import React from "react";

interface Props {}

export const CashbackLogo: FC<Props> = React.memo(() => {
  return (
    <span
      className=" text-uppercase fw-bold fst-italic fs-2"
      style={{ fontFamily: "Open Sans" }}
    >
      cashback
    </span>
  );
});
