import { useCallback, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./core/Auth";

export function Logout() {
  const { logout } = useAuth();

  const allLogout = useCallback(async () => {
    debugger;
    await logout();
    document.location.reload();
  }, [logout]);

  useEffect(() => {
    allLogout();
  }, [logout, allLogout]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}
