/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Меню
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="profile-user"
        title="Пользователи"
        fontIcon="bi-layers"
      />

      {/* <SidebarMenuItem
        to="/apps/agent-management/agents"
        icon="setting"
        title="Агенты"
        fontIcon="bi-layers"
      /> */}
      <SidebarMenuItem
        to="/apps/referral-management/referrals"
        icon="abstract-24"
        title="Реферальные ссылки"
      />

      <SidebarMenuItemWithSub
        to="/apps/withdraws/*"
        title="Выводы средств"
        icon="switch"
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/apps/withdraws/withdraw-list"
          title="Вывод с сабаккаунта"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/apps/withdraws/cashback-list"
          title="Запросы кэшбэка "
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="/apps/faq"
        icon="book-open"
        title="FAQ"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/apps/warning/create"
        icon="information"
        title="Сообщение об ошибке"
        fontIcon="bi-layers"
      />
    </>
  );
};

export { SidebarMenuMain };
