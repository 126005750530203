/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
import { useAuth } from "../../../../app/modules/auth";

const SidebarFooter = () => {
  const { logout } = useAuth();

  const intl = useIntl();

  return (
    <div
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      id="kt_app_sidebar_footer"
    >
      <button
        className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap text-hover-primary px-0 h-40px w-100 "
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="Выйти"
        onClick={logout}
      >
        <span className="btn-label">
          {" "}
          {intl.formatMessage({ id: "AUTH.LOGOUT.TITLE" })}
        </span>
        {/* <KTIcon iconName="document" className="btn-icon fs-2 m-0" /> */}
      </button>
    </div>
  );
};

export { SidebarFooter };
