import axios, { AxiosResponse } from "axios";
import { AuthModel, TotpLoginModel, UserModel } from "..";
import { RefreshToken, TokenFrom2FA } from "../../../../api/core-contract";
import { useMutation } from "react-query";

const API_URL = process.env.REACT_APP_API_URL;

export const REGISTER_URL = `${API_URL}/signup`;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`;

export const LOGIN_URL = `${API_URL}/login/2fa/token`;

export const REFRESH_URL = `${API_URL}/login/refresh`;

export const LOGOUT = `${API_URL}/login/logout`;

export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset-password`;

export const RESET_PASSWORD = `${API_URL}/auth/update-password`;

export const REGISTRATION_CONFIRM = `${API_URL}/auth/registration`;

export const UPDATE_PASSWORD_CONFIRM = `${API_URL}/auth/update-password`;

export const FREEWIN_CONFIRM_WITHDRAW = `${API_URL}/freewin/confirm/withdraw`;

export const SET_2FA_CODE = `${API_URL}/login/totp/confirm`;

export async function login(
  params: URLSearchParams
  // token: string
): Promise<AuthModel> {
  // const headers = {
  //   "g-recaptcha-response": token,
  // };

  const response = await axios.post<AuthModel>(LOGIN_URL, params);
  return response.data;
}

export const useConfirmLoginTotp = () => {
  return useMutation<AuthModel, AuthModel, TotpLoginModel>(
    "set-2fa-key",
    async (sendData: TotpLoginModel) => {
      const { data } = await axios.post(
        `${SET_2FA_CODE}?session_id=${sendData.session_id}&totp_code=${sendData.totp_code}`
      );
      return data;
    }
  );
};

export async function logoutFromServer(
  refreshToken: RefreshToken
): Promise<void> {
  await axios.delete(LOGOUT, { data: refreshToken });
}

export async function register(
  email: string,
  nickname: string,
  password: string,
  promo: string,
  token: string
): Promise<AuthModel> {
  const headers = {
    "g-recaptcha-response": token,
  };

  const response = await axios.post<AuthModel>(
    REGISTER_URL,
    {
      email,
      nickname,
      promo,
      password,
    },
    {
      headers: headers,
    }
  );
  return response.data;
}

export async function refreshToken(
  refreshToken: RefreshToken
): Promise<AuthModel> {
  const params = new URLSearchParams();
  params.append("refresh_token", refreshToken.toString());

  const response = await axios.post<AuthModel>(REFRESH_URL, params);

  return response.data;
}

export async function updatePassword(
  token: string,
  password: string
): Promise<void> {
  await axios.post(
    UPDATE_PASSWORD_CONFIRM,
    { password },
    {
      params: { token },
    }
  );
}

export async function resetPassword(
  email: string
): Promise<{ result: boolean }> {
  const params = new URLSearchParams();
  params.append("email", email);

  const response = await axios.post<{ result: boolean }>(
    REQUEST_PASSWORD_URL,
    {},
    { params }
  );
  return response.data;
}

export async function getUserByToken(): Promise<UserModel> {
  const response = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data;
}

export async function confirmRegistration(token: TokenFrom2FA): Promise<void> {
  await axios.post(REGISTRATION_CONFIRM, { token });
}

export async function confirmWithdrawal(token: TokenFrom2FA): Promise<void> {
  const response = await axios.post(FREEWIN_CONFIRM_WITHDRAW, { token });
  return response.data;
}
